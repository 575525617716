import { Constants } from "appConstants";
import { IcoArrowDown, IcoHint } from "assets/icons";
import { Fragment, useMemo, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { Tooltip } from "react-tooltip";
import { OptionLike } from "../../helpers/OptionHelper";

export interface MultiSelectProps {
	label?: string;
	className?: string;
	name: string;
	tooltip?: string;
	error?: string | string[];
	touched?: boolean;
	noOptionsMessage?: string;
	placeholder?: string;
	value: string[];
	isDisabled?: boolean;
	options: OptionLike[];
	tooltipId?: string;
	setFieldValue: (name: string, value: (string | number)[]) => void;
}

export default function MultiSelect({
	label,
	className,
	name,
	tooltip,
	error,
	touched,
	noOptionsMessage = "Nenhuma opção",
	placeholder = "Selecione",
	setFieldValue,
	isDisabled,
	value,
	options,
	tooltipId
}: MultiSelectProps): JSX.Element {
	const [inputValue, setInputValue] = useState("");

	const filteredOptions = useMemo(() => {
		return options
			.filter((option) => !value.includes(option.value))
			.filter((option) => {
				const optionLabel = String(option?.label);
				return optionLabel.toLowerCase().includes(inputValue.toLowerCase());
			});
	}, [inputValue, options, value]);

	const handleSelect = (selectedOption: OptionLike) => {
		setFieldValue(name, [...value, selectedOption.value]);
		setInputValue("");
	};

	const handleRemove = (removedValue: string) => {
		setFieldValue(
			name,
			value.filter((v) => v !== removedValue)
		);
	};

	const handleClearAll = () => {
		setFieldValue(name, []);
	};

	const innerTooltipId = useMemo(
		() => tooltipId ?? `tooltip-${Math.trunc(Math.random() * 100000)}`,
		[]
	);

	return (
		<div className={`container ${className}`}>
			{label && (
				<div className="flex gap-1 items-center pb-1">
					<label className="text-sm" htmlFor={name}>
						{label}
					</label>
					{tooltip && (
						<>
							<span id={innerTooltipId}>
								<IcoHint size="18" />
							</span>
							<Tooltip
								anchorSelect={`#${innerTooltipId}`}
								style={{ zIndex: Constants.zIndexes.tooltip }}
							>
								<div className="max-w-[96vw] md:max-w-[30rem] text-wrap">
									{tooltip}
								</div>
							</Tooltip>
						</>
					)}
				</div>
			)}
			<Combobox
				as="div"
				disabled={isDisabled}
				onChange={(selectedOption) => handleSelect(selectedOption)}
				multiple
			>
				{({ open }) => (
					<div className={`relative ${error ? "multiselect-error" : ""}`}>
						<Combobox.Button
							as="div"
							className={`multiselect-button px-4 py-2 ${
								isDisabled ? "multiselect-button-disabled" : ""
							}`}
						>
							<div className="gap-2 items-center">
								{value.map((selectedValue) => {
									const selectedOption = options.find(
										(option) => option.value === selectedValue
									);

									if (!selectedOption) {
										return null;
									}
									return (
										<div
											key={selectedOption?.value}
											className="multiselect-tag m-1"
										>
											<span className="multiselect-option-label">
												{selectedOption?.label}
											</span>
											<button
												type="button"
												className="multiselect-remove"
												onMouseDown={(event) => {
													event.stopPropagation();
													handleRemove(selectedValue);
												}}
											>
												&times;
											</button>
										</div>
									);
								})}

								<Combobox.Input
									className="multiselect-input flex-1"
									placeholder={!value.length ? placeholder : ""}
									value={inputValue}
									onChange={(event) => setInputValue(event.target.value)}
									disabled={isDisabled}
								/>
							</div>
							<div className="flex items-center gap-2">
								{value.length > 0 && (
									<button
										type="button"
										className="multiselect-remove-all"
										onMouseDown={(event) => {
											event.stopPropagation();
											handleClearAll();
										}}
									>
										&times;
									</button>
								)}
								<IcoArrowDown />
							</div>
						</Combobox.Button>

						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
							show={open}
						>
							<Combobox.Options className="multiselect-options">
								{filteredOptions.length > 0 ? (
									filteredOptions.map((option) => (
										<Combobox.Option
											key={option.value}
											value={option}
											as={Fragment}
										>
											{({ focus }) => (
												<div
													className={`multiselect-option ${
														focus ? "active-option" : ""
													}`}
													onClick={() => handleSelect(option)}
												>
													{option.label}
												</div>
											)}
										</Combobox.Option>
									))
								) : (
									<div>{noOptionsMessage}</div>
								)}
							</Combobox.Options>
						</Transition>
					</div>
				)}
			</Combobox>
			{error && touched && (
				<div className="white-space--pre-line mt-1 text-xs">
					{Array.isArray(error) ? error.join("\n") : error}
				</div>
			)}
		</div>
	);
}
