import { Constants } from "appConstants";
import { FormikProps } from "formik";
import {
	ApplicationStatus,
	HighwayConcessionData,
	InterventionTypeCategoryData,
	InterventionTypeData
} from "models/types";
import { ApplicationFormDocumentFolder, DocumentLike } from "services/types";

type SimpleSpread<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>;

export enum PersonTypeOptions {
	physical = "PF",
	juridical = "PJ"
}

export enum ProprietorDocumentType {
	CPF = "CPF",
	CNPJ = "CNPJ"
}

export enum ApplicationProprietorDataFields {
	proprietorType = "proprietorType",

	proprietorAddressPostalCode = "proprietorAddressPostalCode",
	proprietorAddressState = "proprietorAddressState",
	proprietorAddressCity = "proprietorAddressCity",
	proprietorAddressNeighbourhood = "proprietorAddressNeighbourhood",
	proprietorAddressStreet = "proprietorAddressStreet",
	proprietorAddressNumber = "proprietorAddressNumber",
	proprietorAddressComplement = "proprietorAddressComplement",

	proprietorName = "proprietorName",
	proprietorPhoneNumber = "proprietorPhoneNumber",
	proprietorEmail = "proprietorEmail",
	proprietorDocument = "proprietorDocument",
	proprietorDocumentType = "proprietorDocumentType",
	proprietorBirthDate = "proprietorBirthDate",
	proprietorStateRegistration = "proprietorStateRegistration",
	proprietorCityRegistration = "proprietorCityRegistration",
	proprietorContactName = "proprietorContactName",

	applicantName = "applicantName",
	applicantPhoneNumber = "applicantPhoneNumber",
	applicantRole = "applicantRole",
	applicantEmail = "applicantEmail",
	additionalContactEmail = "additionalContactEmail"
}

export interface ApplicationProprietorData {
	id?: string;
	proprietorType: PersonTypeOptions;

	proprietorAddressPostalCode: string | null;
	proprietorAddressState: Constants.FederalUnits | null;
	proprietorAddressCity: string | null;
	proprietorAddressNeighbourhood: string | null;
	proprietorAddressStreet: string | null;
	proprietorAddressNumber: string | null;
	proprietorAddressComplement: string | null;

	proprietorName: string | null;
	proprietorPhoneNumber: string | null;
	proprietorEmail: string | null;
	proprietorDocument: string | null;
	proprietorDocumentType: ProprietorDocumentType;
	proprietorBirthDate: string | null;
	proprietorStateRegistration: string | null;
	proprietorCityRegistration: string | null;
	proprietorContactName: string | null;

	applicantName: string | null;
	applicantPhoneNumber: string | null;
	applicantRole: string | null;
	applicantEmail: string | null;
	additionalContactEmail: string | null;
}

export interface ApplicationDocument extends DocumentLike {
	id?: string;
}

export enum ApplicationFields {
	status = "status",
	highwayConcession = "highwayConcession",
	interventionType = "interventionType",
	interventionTypeCategory = "interventionTypeCategory",
	applicationProprietorData = "applicationProprietorData",
	documentFolders = "documentFolders",
	initialKilometer = "initialKilometer",
	initialMeter = "initialMeter",
	initialUf = "initialUf",
	finalKilometer = "finalKilometer",
	finalMeter = "finalMeter",
	finalUf = "finalUf",
	directions = "directions",
	description = "description"
}

export interface ApplicationData {
	id?: string;
	applicationProcessCode?: string;
	status: ApplicationStatus;
	highwayConcession: HighwayConcessionData | null;
	interventionType: InterventionTypeData | null;
	interventionTypeCategory: InterventionTypeCategoryData | null;
	applicationProprietorData: ApplicationProprietorData;
	documentFolders: ApplicationFormDocumentFolder[];
	initialKilometer: number | undefined | null;
	initialMeter: number | undefined | null;
	initialUf: string | null;
	finalKilometer: number | undefined | null;
	finalMeter: number | undefined | null;
	finalUf: string | null;
	directions: ApplicationInterventionDirection[];
	description: string | null;
}

export type ApplicationFormData = SimpleSpread<
	ApplicationData,
	{
		documentFolders: Record<string, ApplicationFormDocumentFolder>;
	}
>;

export interface ApplicationProps extends ApplicationData {
	applicationProcessCode?: string;
	highwayName?: string;
	highwayCode?: string;
	concessionaireName?: string;
	issuedAt?: string;
	interventionTypeName?: string;
	currentUserResponsibleEmail?: string;
	currentUserResponsibleGroup?: Constants.UserRoleGroups;
	currentResponsibleUserName?: string;
	regulatoryAgencyName?: string;
	analysisLimitDatetime?: string;
	concessionaireEnabled?: boolean;
}

export enum ApplicationStep {
	Highway = 0,
	Intervention = 1,
	ApplicationData = 2,
	ApplicationDocuments = 3,
	ApplicationReview = 4
}

export interface ApplicationFormik {
	formik: FormikProps<ApplicationFormData>;
}

export enum ApplicationInterventionDirectionFields {
	direction = "direction",
	isInitialDirection = "isInitialDirection"
}

export enum ApplicationDirectionPosition {
	initial = "initial",
	final = "final"
}

export enum InterventionDirectionValue {
	CANTEIRO_CENTRAL = 0,
	NORTH = 1,
	SOUTH = 2,
	EAST = 3,
	WEST = 4,
	INTERNAL = 5,
	EXTERNAL = 6
}

export const DirectionLabelsRecord: Record<InterventionDirectionValue, string> =
	{
		[InterventionDirectionValue.CANTEIRO_CENTRAL]: "Canteiro central",
		[InterventionDirectionValue.NORTH]: "Norte",
		[InterventionDirectionValue.SOUTH]: "Sul",
		[InterventionDirectionValue.EAST]: "Leste",
		[InterventionDirectionValue.WEST]: "Oeste",
		[InterventionDirectionValue.INTERNAL]: "Interno",
		[InterventionDirectionValue.EXTERNAL]: "Externo"
	};

export interface ApplicationInterventionDirection {
	id?: string;
	direction: InterventionDirectionValue;
	isInitialDirection: boolean;
}
