import { ColumnDef } from "@tanstack/react-table";
import { IcoFile, IcoMessage } from "assets/icons";
import { ApplicationStatusLabel, Button, Pagination, Table } from "components";
import { DateTimeHelper } from "helpers";
import { ApplicationListData } from "pages/LoggedInHome/types";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import "react-tooltip/dist/react-tooltip.css";
import "./styles.scss";

interface ApplicationsTableProps {
	applications: ApplicationListData[];
	pageSize: number;
	currentPage: number;
	totalPages: number;
	onPageChange: (selectedPage: number, pageSize: number) => void;
}

const columns: ColumnDef<ApplicationListData>[] = [
	{
		accessorKey: "applicationProcessCode",
		header: "Nº do processo",
		enableSorting: false,
		meta: {
			columnClass: "process-number-column"
		}
	},
	{
		accessorKey: "issuedAt",
		header: "Data da solicitação",
		enableSorting: false,
		cell: (row) => {
			return DateTimeHelper.formatDate(new Date(row.getValue() as string));
		}
	},
	{
		accessorKey: "interventionType",
		header: "Tipo de intervenção",
		enableSorting: false,
		meta: {
			columnClass: "intervention-type-column"
		}
	},
	{
		accessorKey: "highwayName",
		header: "Rodovia",
		enableSorting: false,
		meta: {
			columnClass: "highway-name-column"
		}
	},
	{
		accessorKey: "concessionaireName",
		header: "Concessionária",
		enableSorting: false
	},
	{
		accessorKey: "status",
		header: "Status do processo",
		enableSorting: false,
		cell: (row) => <ApplicationStatusLabel status={row.getValue() as string} />
	},
	{
		id: "action",
		header: "Ações",
		enableSorting: false,
		cell: ({ row }) => (
			<span className="flex gap-1">
				<Tooltip anchorSelect=".detail-button" place="top">
					Detalhes da solicitação
				</Tooltip>
				<Link to={`/solicitacao/${row.original.id}`}>
					<Button hierarchy="ghost" kind="icon" className="detail-button">
						<IcoFile />
					</Button>
				</Link>

				<Tooltip anchorSelect=".message-button" place="top">
					Mensagens
				</Tooltip>
				<Link to={`/solicitacao/${row.original.id}/analises`}>
					<Button hierarchy="ghost" kind="icon" className="message-button">
						<IcoMessage />
					</Button>
				</Link>
			</span>
		),
		meta: {
			columnClass: "actions-column"
		}
	}
];

export default function ApplicationsTable({
	applications,
	pageSize,
	currentPage,
	totalPages,
	onPageChange
}: ApplicationsTableProps) {
	return (
		<div className="external-applications-table">
			<div className="overflow-auto h-[calc(100vh-16rem)] scrollbar-width--thin">
				<Table data={applications} columns={columns} />
			</div>
			<Pagination
				totalPages={totalPages}
				currentPage={currentPage}
				onPageChange={onPageChange}
				pageSize={pageSize}
			/>
		</div>
	);
}
