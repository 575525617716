import { Combobox, Transition } from "@headlessui/react";
import { Constants } from "appConstants";
import { IcoArrowDown, IcoHint } from "assets/icons";
import { Fragment, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import { SelectFormProps } from "./types";

import "./styles.scss";

export default function SelectForm<V>({
	isDisabled = false,
	isClearable = true,
	isRtl = false,
	name,
	onMenuOpen,
	onMenuClose,
	label = "",
	tooltip,
	options,
	placeholder = "Selecione",
	noOptionsMessage = "Nenhuma opção",
	value,
	onChange,
	className = "",
	error,
	touched,
	menuPlacement = "auto",
	tooltipId,
	isSelectVersion,
	getOptionValue,
	setFieldValue,
	defaultValue
}: Readonly<SelectFormProps<V>>): JSX.Element {
	const [selectedOption, setSelectedOption] = useState(defaultValue || value);
	const [inputValue, setInputValue] = useState("");

	const onSelectChange = useMemo(() => {
		return (newFieldValue: any) => {
			onChange?.(newFieldValue);

			const newValue =
				newFieldValue && getOptionValue
					? getOptionValue(newFieldValue)
					: newFieldValue;

			setFieldValue?.(name, newValue);
			setSelectedOption(newFieldValue);
		};
	}, [setFieldValue, name, onChange, getOptionValue]);

	const filteredOptions = useMemo(() => {
		if (!inputValue.trim()) {
			return options;
		}

		return options?.filter((option: any) =>
			String(option.label)
				.toLowerCase()
				.includes(String(inputValue.toLowerCase()))
		);
	}, [options, inputValue]);

	const innerTooltipId = useMemo(
		() => tooltipId ?? `tooltip-${Math.trunc(Math.random() * 100000)}`,
		[]
	);

	if (!options) return <div />;

	return (
		<div className={`container ${className} ${isRtl ? "rtl" : ""}`}>
			{label && (
				<div className="flex gap-1 items-center pb-1">
					<label className="text-sm" htmlFor={name}>
						{label}
					</label>
					{tooltip && (
						<>
							4
							<span id={innerTooltipId}>
								<IcoHint size="18" />
							</span>
							<Tooltip
								anchorSelect={`#${innerTooltipId}`}
								opacity={1}
								style={{ zIndex: Constants.zIndexes.tooltip }}
							>
								<div className="max-w-[96vw] md:max-w-[30rem] text-wrap">
									{tooltip}
								</div>
							</Tooltip>
						</>
					)}
				</div>
			)}
			<Combobox
				value={value ?? null}
				onChange={onSelectChange}
				disabled={isDisabled}
			>
				{({ open }) => {
					if (open) {
						onMenuOpen?.();
					} else {
						onMenuClose?.();
					}
					return (
						<div className={`relative mr-3 ${error && touched ? "error" : ""}`}>
							<Combobox.Button
								as="div"
								className={`select-button px-4 py-2  ${
									isDisabled ? "select-button-disabled" : ""
								}`}
							>
								<div className="flex flex-wrap gap-2 items-center w-full">
									<div className="select-option w-full flex">
										{!isSelectVersion ? (
											<>
												<Combobox.Input
													type="text"
													className="select-input flex-1 w-full"
													value={
														selectedOption?.label || inputValue || value || ""
													}
													onChange={(event) =>
														setInputValue(event.target.value)
													}
													placeholder={!value?.label ? placeholder : ""}
													disabled={isDisabled}
												/>

												{isClearable && !isDisabled && selectedOption && (
													<button
														type="button"
														onMouseDown={(event) => {
															event.preventDefault();
															setInputValue("");

															onSelectChange(null);
														}}
														className="clear-button"
													>
														&times;
													</button>
												)}
											</>
										) : (
											<span>{selectedOption?.label || placeholder}</span>
										)}
									</div>
								</div>
								<IcoArrowDown />
							</Combobox.Button>

							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
								show={open}
							>
								<Combobox.Options
									className={`select-options ${
										menuPlacement === "top"
											? "placement-top"
											: "placement-bottom"
									}`}
								>
									{filteredOptions?.length ? (
										filteredOptions.map((option: any) => (
											<Combobox.Option
												key={option.value}
												value={option}
												className={({ focus }) =>
													`select-option ${focus ? "active-option" : ""}`
												}
											>
												{option.label}
											</Combobox.Option>
										))
									) : (
										<div className="no-options">{noOptionsMessage}</div>
									)}
								</Combobox.Options>
							</Transition>
						</div>
					);
				}}
			</Combobox>
			{error && touched && (
				<div className="white-space--pre-line mt-1 text-xs">
					{Array.isArray(error) ? error.join("\n") : error}
				</div>
			)}
		</div>
	);
}
