import { Constants } from "appConstants";
import { FieldsSection } from "pages/ApplicationDetail/modules/ApplicationDetailData";
import {
	ApplicationData,
	ApplicationInterventionDirection,
	ApplicationProps,
	DirectionLabelsRecord
} from "pages/NewApplication/steps/types";
import { ApplicationFormDocumentFolder } from "services/types";
import { ApplicationProprietor } from "./ApplicationProprietor";
import { UserAuthorities } from "./auth/types";
import { BaseModel } from "./BaseModel";
import { HighwayConcession } from "./HighwayConcession";
import { InterventionType } from "./InterventionType";
import { InterventionTypeCategory } from "./InterventionTypeCategory";
import {
	ApplicationFinalStatus,
	ApplicationStatus,
	InterventionTypeData
} from "./types";

export class Application extends BaseModel<ApplicationData> {
	id: string;
	description: string;
	initialKilometer: number;
	initialMeter: number;
	initialUf: string;
	finalKilometer: number;
	finalMeter: number;
	finalUf: string;
	applicationProcessCode: string;
	directions: ApplicationInterventionDirection[];
	documentFolders: ApplicationFormDocumentFolder[];
	applicationProprietorData: ApplicationProprietor;
	interventionType: InterventionTypeData;
	interventionTypeCategory: InterventionTypeCategory;
	highwayConcession: HighwayConcession;
	status: ApplicationStatus | string;
	issuedAt: string;
	interventionTypeName: string;
	highwayName: string;
	highwayCode: string;
	concessionaireName: string;
	currentUserResponsibleEmail: string | undefined;
	currentUserResponsibleGroup: Constants.UserRoleGroups | undefined;
	currentResponsibleUserName: string | undefined;
	regulatoryAgencyName: string;
	analysisLimitDatetime: string;
	concessionaireEnabled: boolean;

	constructor(data: ApplicationProps) {
		super(data);
		this.id = data.id ?? "";
		this.status = data.status ?? ApplicationStatus.RASCUNHO;
		this.applicationProcessCode = data.applicationProcessCode ?? "";
		this.issuedAt = data.issuedAt ?? "";
		this.interventionTypeName = data.interventionTypeName ?? "";
		this.highwayName = data.highwayName ?? "";
		this.highwayCode = data.highwayCode ?? "";
		this.concessionaireName = data.concessionaireName ?? "";
		this.description = data.description ?? "";
		this.initialKilometer = data.initialKilometer ?? 0;
		this.initialMeter = data.initialMeter ?? 0;
		this.initialUf = data.initialUf ?? "";
		this.finalKilometer = data.finalKilometer ?? 0;
		this.finalMeter = data.finalMeter ?? 0;
		this.finalUf = data.finalUf ?? "";
		this.directions = data.directions ?? [];
		this.documentFolders = data.documentFolders
			? Object.values(data.documentFolders)
			: [];
		this.applicationProprietorData = ApplicationProprietor.getValueOrNew(
			data.applicationProprietorData
		);
		this.interventionType = InterventionType.getValueOrNew(
			data.interventionType ?? {}
		);
		this.interventionTypeCategory = InterventionTypeCategory.getValueOrNew(
			data.interventionTypeCategory ?? {}
		);
		this.highwayConcession = HighwayConcession.getValueOrNew(
			data.highwayConcession ?? {}
		);
		this.currentUserResponsibleEmail = data.currentUserResponsibleEmail;
		this.currentUserResponsibleGroup = data.currentUserResponsibleGroup;
		this.currentResponsibleUserName = data.currentResponsibleUserName;
		this.regulatoryAgencyName = data.regulatoryAgencyName ?? "";
		this.analysisLimitDatetime = data.analysisLimitDatetime ?? "";
		this.concessionaireEnabled = !!data.concessionaireEnabled;
	}

	static createFromResponse(response: ApplicationProps) {
		if (!response) {
			return response;
		}

		const interventionTypeName =
			response.interventionTypeName ||
			(typeof response.interventionType === "string"
				? response.interventionType
				: response.interventionType?.name ?? "");

		return new this({
			...response,
			interventionTypeName,
			highwayConcession:
				response.highwayConcession &&
				HighwayConcession.createFromResponse(response.highwayConcession)
		});
	}

	positionRepresentation(position: "initial" | "final") {
		if (position === "initial") {
			const initialMeterOptional = this.initialMeter
				? ` + ${this.initialMeter} m`
				: "";
			return `km ${this.initialKilometer}${initialMeterOptional} - ${this.initialUf}`;
		}
		const finalMeterOptional = this.finalMeter ? ` + ${this.finalMeter} m` : "";
		return `km ${this.finalKilometer}${finalMeterOptional} - ${this.finalUf}`;
	}

	get initialDirections() {
		return this.directions
			.filter((direction) => direction.isInitialDirection)
			.map((direction) => DirectionLabelsRecord[direction.direction])
			.join("/");
	}

	get finalDirections() {
		return this.directions
			.filter((direction) => !direction.isInitialDirection)
			.map((direction) => DirectionLabelsRecord[direction.direction])
			.join("/");
	}

	isCurrentUserResponsible(userEmail: string | undefined) {
		if (!userEmail) return false;
		return this.currentUserResponsibleEmail === userEmail;
	}

	isCurrentUserInResponsibleGroup(authorities: UserAuthorities[]): boolean {
		const responsibleGroup =
			this.currentUserResponsibleGroup ?? Constants.UserRoleGroups.SOLICITANTE;
		return !!authorities.find(
			(ug) =>
				ug === UserAuthorities.ADMINISTRADOR ||
				ug.replace("ROLE_", "") === responsibleGroup
		);
	}

	hasUserResponsible() {
		return !!this.currentUserResponsibleEmail;
	}

	isInFinalStatus() {
		return Application.isInFinalStatus(this.status as ApplicationStatus);
	}

	static isInFinalStatus(status: ApplicationStatus) {
		return ApplicationFinalStatus.includes(status);
	}

	isInBOReadOnlyStatus() {
		const boReadOnlyStatus = [
			ApplicationStatus.CONCLUIDO,
			ApplicationStatus.CANCELADO,
			ApplicationStatus.SOLICITANTE_PENDENTE
		];
		return boReadOnlyStatus.includes(this.status as ApplicationStatus);
	}

	get applicationDetails(): FieldsSection[] {
		return [
			{
				title: "",
				nameValueFields: [
					{
						name: "Concessionária",
						value:
							this.highwayConcession?.concessionaire?.name ||
							this.concessionaireName
					},
					{
						name: "Rodovia selecionada",
						value: this.highwayConcession?.highway?.fullName
					},
					{
						name: "Tipo de intervenção",
						value: this.interventionTypeName
					},
					{
						name: "Categoria de intervenção",
						value: this.interventionTypeCategory?.name,
						condition: !!this.interventionTypeCategory?.name
					},
					{
						name: "Sentido",
						value: this.highwayConcession?.highway?.directionRepresentation
					},
					{
						name: "km inicial",
						value: this.highwayConcession?.positionRepresentation("initial")
					},
					{
						name: "km final",
						value: this.highwayConcession?.positionRepresentation("final")
					}
				]
			},
			{
				title: "Informe o km, metro e UF da intervenção",
				nameValueFields: [
					{
						name: "Inicial",
						value: this.positionRepresentation("initial")
					},
					{
						name: "Sentido inicial",
						value: this.initialDirections
					},
					{
						name: "Final",
						value: this.positionRepresentation("final")
					},
					{
						name: "Sentido final",
						value: this.finalDirections
					}
				]
			}
		];
	}

	get applicantDetails(): FieldsSection[] {
		return [
			{
				title: "",
				nameValueFields: [
					{
						name: "Perfil",
						value: this.applicationProprietorData?.proprietorTypeLabel
					},
					{
						name: this.applicationProprietorData?.isPJ
							? "Razão social"
							: "Nome completo",
						value: this.applicationProprietorData?.proprietorName
					},
					{
						name: this.applicationProprietorData?.proprietorDocumentType,
						value: this.applicationProprietorData?.proprietorDocument
					},
					{
						name: "Inscrição estadual",
						value: this.applicationProprietorData?.proprietorStateRegistration,
						condition: this.applicationProprietorData?.isPJ
					},
					{
						name: "Inscrição municipal",
						value: this.applicationProprietorData?.proprietorCityRegistration,
						condition: this.applicationProprietorData?.isPJ
					},
					{
						name: "Data de nascimento",
						value: this.applicationProprietorData?.proprietorBirthDateLabel,
						condition: this.applicationProprietorData?.isPF
					}
				]
			},
			{
				title: "Endereço cadastral",
				nameValueFields: [
					{
						name: "CEP",
						value: this.applicationProprietorData?.proprietorAddressPostalCode
					},
					{
						name: "Estado",
						value: this.applicationProprietorData?.proprietorAddressState
					},
					{
						name: "Cidade",
						value: this.applicationProprietorData?.proprietorAddressCity
					},
					{
						name: "Bairro",
						value:
							this.applicationProprietorData?.proprietorAddressNeighbourhood
					},
					{
						name: "Endereço",
						value: this.applicationProprietorData?.proprietorAddressStreet
					},
					{
						name: "Número",
						value: this.applicationProprietorData?.proprietorAddressNumber
					},
					{
						name: "Complemento",
						value: this.applicationProprietorData?.proprietorAddressComplement,
						condition:
							!!this.applicationProprietorData?.proprietorAddressComplement
					}
				]
			},
			{
				title: "Dados para contato",
				nameValueFields: [
					{
						name: "Nome do contato",
						value: this.applicationProprietorData?.proprietorContactName,
						condition: !!this.applicationProprietorData?.proprietorContactName
					},
					{
						name: "Telefone",
						value: this.applicationProprietorData?.proprietorPhoneNumber
					},
					{
						name: "E-mail",
						value: this.applicationProprietorData?.proprietorEmail
					},
					{
						name: (
							<div>
								E-mail extra{" "}
								<span className="text-neutral-low-300">(opcional)</span>
							</div>
						),
						value: this.applicationProprietorData?.additionalContactEmail,
						condition: !!this.applicationProprietorData?.additionalContactEmail
					}
				]
			},
			{
				title: "Contato direto para tratar a solicitação",
				nameValueFields: [
					{
						name: "Nome",
						value: this.applicationProprietorData?.applicantName
					},
					{
						name: "Qual seu papel da intervenção?",
						value: this.applicationProprietorData?.applicantRole
					},
					{
						name: "Telefone",
						value: this.applicationProprietorData?.applicantPhoneNumber
					},
					{
						name: "E-mail",
						value: this.applicationProprietorData?.applicantEmail
					}
				]
			}
		];
	}
}
